<template>
	<div class="account">
		<vue-headful
			:title="[$t('menu.account')] + '- UNDERMRKT'"
			description="Description from vue-headful"
		/>
		<div class="container">
			<h1>
				<div class="icon">
					<font-awesome-icon :icon="['fas', 'user']" />
				</div>
				{{ $t("menu.account") }}
			</h1>
			<div class="row">
				<div class="content">
					<div class="card">
						<div class="group">
							<div class="group__title">
								{{ $t("account.billing_title") }}
							</div>
							<div class="row d-block">
								<div class="label">
									{{ $t("pricing.current_plan") }}
								</div>
								<div
									class="value d-flex justify-content-between plan-inner"
								>
									<div class="plan-object">
										<span
											class="plan-name"
											v-bind:style="{
												background: plan.color,
											}"
											>{{ plan.name }}</span
										>
										<div class="plan-item">
											<span>{{
												plan.sellers_count
											}}</span>
											{{ $t("sidebar.sellers") }}
										</div>
										<div class="plan-item">
											<span>{{
												plan.keywords_count
											}}</span>
											{{ $t("sidebar.keywords") }}
										</div>
										<div class="plan-item">
											<span
												>{{ plan.sellers_rate }}
												{{ $t("global.sec") }}</span
											>
											{{ $t("sidebar.update_rate") }} ({{
												$t("sidebar.sellers")
											}})
										</div>
										<div class="plan-item">
											<span
												>{{ plan.keywords_rate }}
												{{ $t("global.sec") }}</span
											>
											{{ $t("sidebar.update_rate") }} ({{
												$t("sidebar.keywords")
											}})
										</div>
										<div class="plan-item price">
											<span>{{ plan.price }} $</span>
											/mo.
										</div>
									</div>
									<!-- plan item -->
									<div
										class="cta text-right"
										style="min-width: 200px;"
									>
										<button-primary
											:onClick="gotToPricing"
											:class="'small'"
											>{{
												$t("account.change_plan")
											}}</button-primary
										>
									</div>
								</div>
							</div>
							<!-- row -->
							<div class="row">
								<div class="label">
									<div class="label">
										{{ $t("account.plan_status") }}
									</div>
									<div class="value">
										<div class="status active">ACTIVE</div>
									</div>
								</div>
							</div>
							<!-- row -->
							<div class="row">
								<div>
									<div class="label">
										{{ $t("account.last_transactions") }}
									</div>
									<div class="transactions">
										<div
											v-for="t in orderedTransations"
											:key="t.order_id"
											class="transactions__item"
										>
											<div class="card-number">
												<template
													v-if="t.card_type == 'mc'"
												>
													<img
														src="@/assets/mc.png"
														alt
													/>
												</template>
												<template
													v-if="t.card_type == 'visa'"
												>
													<img
														src="@/assets/visa.png"
														alt
													/>
												</template>
												<template v-else></template>
												{{ t.card }}
											</div>
											<div class="d-flex">
												<div class="descr">
													<div class="text">
														{{ t.description }}
													</div>
													<div class="date">
														{{
															t.date
																| moment(
																	"LL H:mm"
																)
														}}
													</div>
												</div>
												<div class="right">
													<div class="price">
														{{ t.amount }}$
													</div>
													{{ t.status }}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<sidebar>
					<template v-slot:title>Notifications</template>
					<template v-slot:text
						>Here You can check the satus of each notifcation
						channel and get intructions about how to
						setup.</template
					>
				</sidebar>
			</div>
		</div>
	</div>
</template>
<script>
import firebase from "firebase/app";
import "firebase/auth"; // for authentication
import "firebase/storage"; // for storage
import "firebase/firestore"; // for cloud firestore
import "firebase/functions"; // for cloud functions
import { mapGetters } from "vuex";
import sidebar from "@/components/Sidebar.vue";
import _ from "lodash";
export default {
	name: "Account",
	data() {
		return {
			tip: {
				copyId: "Copy Your user ID to clipboard",
			},
			showTooltip: false,
			transactions: [],
		};
	},
	components: {
		sidebar,
	},
	computed: {
		...mapGetters({
			user: "user",
			plans: "plans",
			userSellers: "userSellers",
			userKeywords: "userKeywords",
		}),
		orderedTransations: function() {
			return _.orderBy(
				this.transactions,
				function(e) {
					return e.date;
				},
				["desc"]
			);
		},
		plan: function() {
			if (this.user.profile) {
				var planId = this.user.profile.plan;
				var plan;
				for (var i = 0, len = this.plans.length; i < len; i++) {
					if (this.plans[i].id == planId) {
						plan = this.plans[i];
					}
				}
				return plan;
			} else {
				return 0;
			}
		},
	},
	methods: {
		gotToPricing:function(){
			this.$router.push({name: 'pricing'});
		},
		getTransactions: async function() {
			var db = firebase.firestore();
			var docRef = db
				.collection("transactions")
				.where("sender", "==", this.user.profile.user_id)
				.get()
				.then((querySnapshot) => {
					querySnapshot.forEach((doc) => {
						this.transactions.push(doc.data());
					});
				})
				.catch(function(error) {
					//console.log("Error getting documents: ", error);
				});
		},
	},
	beforeCreate: function() {
		document.body.className = "account";
	},
	mounted() {
		this.getTransactions();
	},
};
</script>
<style lang="scss" scoped>
.plan-inner {
	@media (max-width: 768px) {
		flex-direction: column;
		& > div {
			margin-bottom: 20px;
			width: auto;
			&.plan-object {
				width: auto;
			}
			&.cta {
				text-align: left;
			}
		}
	}
}
.card {
	padding: 20px;
}

.plan-object {
	padding: 15px;
	border: 1px solid #e7e7e7;
	box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
	border-radius: 6px;
}

.status {
	display: inline-block;
	background-color: #e7e7e7;
	border-radius: 6px;
	color: #000;
	font-weight: 600;
	padding: 5px 8px;

	&.active {
		background-color: rgba(green, 0.1);
		color: #43c343;
	}
}

.plan-name {
	padding: 3px 10px;
	color: #fff;
	border-radius: 6px;
	font-weight: 500;
	margin: 0px 10px 10px 0px;
	text-transform: uppercase;
	display: inline-block;
	font-size: 18px;
}

.transactions {
	max-height: 500px;
	overflow: scroll;
	padding: 15px;
	background: #e7e7e7;
	&__item {
		margin-bottom: 15px;
		border: 1px solid #e7e7e7;
		padding: 10px;
		border-radius: 8px;
		background: #fff;
		width: 500px;
		justify-content: space-between;
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
		max-width: 100%;
		.d-flex {
			@media (max-width: 768px) {
				flex-direction: column;
			}
		}
		.card-number {
			img {
				height: 28px;
				margin-right: 20px;
			}
			display: flex;
			align-items: center;
			margin-bottom: 10px;
			color: #999;
		}
		.descr {
			margin-right: 20px;

			.text {
				font-size: 18px;
				margin-bottom: 10px;
			}
		}

		.right {
			text-align: right;
			width: 100px;
			font-size: 13px;
			margin-left: auto;
			.price {
				font-size: 18px;
				font-weight: 600;
				margin-bottom: 10px;
			}
		}

		.date {
			font-size: 13px;
			color: #666;
		}
	}
}

.plan-item {
	padding: 3px 0px;
	color: #999;
	width: 260px;

	&.price {
		margin-top: 10px;
		padding-top: 10px;
		border-top: 1px solid #e7e7e7;

		span {
			font-size: 28px;
		}
	}

	span {
		font-weight: bold;
		font-size: 18px;
		color: #000;
	}
}

.icon {
	background-color: #06269c !important;
}
</style>
